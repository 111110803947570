import createPersistedState from "vuex-persistedstate";
import SecureLS from 'secure-ls'
import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/auth";
import helpers from "@/store/helpers";
import faq from "@/store/faq";
import category from "@/store/category";
import service from "@/store/service";
import barber from "@/store/barber";
import message from "@/store/message";


const ls = new SecureLS({isCompression: false});

Vue.use(Vuex)




export default new Vuex.Store({
    modules: {
        auth,
        helpers,
        faq,
        category,
        service,
        barber,
        message,
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })],
})


