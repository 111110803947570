import axios from 'axios'

const state = {
    barber: {},
    barbers: {},
    barber_errors: {},
    selected_services: {},
    barber_loading: false,
}

const getters = {
    barber: state => state.barber,
    barbers: state => state.barbers,
    selected_services: state => state.selected_services,
    barber_errors: state => state.barber_errors,
    barber_loading: state => state.barber_loading,
}

const mutations = {
    setBarber(state, barber) {
        state.barber = barber
    },
    setBarbers(state, barbers) {
        state.barbers = barbers
    },
    setSelectedServices(state, selected_services) {
        state.selected_services = selected_services
    },
    setBarberErrors(state, barber_errors) {
        state.barber_errors = barber_errors
    },
    setBarberLoading(state, barber_loading) {
        state.barber_loading = barber_loading
    },
}

const actions = {
    async storeBarber({commit}, data) {
        commit('setBarberLoading', true)
        commit('setBarberErrors', {})
        try {
            let response = await axios.post(`/api/barbers/store`, data)
            commit('setBarberLoading', false)
            commit('setBarberErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setBarberLoading', false)
            commit('setBarberErrors', error.response.data.errors)
            return false
        }
    },
    async getBarbers({commit}, data) {
        commit('setBarberLoading', true)
        try {
            let response = await axios.get(`/api/barbers`, data)
            commit('setBarbers', response.data)
            commit('setBarberLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setBarberLoading', false)

            return false
        }
    },

    async getBarber({commit}, id) {
        try {
            let response = await axios.get(`/api/barbers/${id}/show`)
            commit('setBarber', response.data?.data)
            commit('setSelectedServices', response.data?.data?.services.map(item => item.id))
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },

    async destroyBarber({commit}, id) {
        commit('setBarberLoading', true)
        try {
            let response = await axios.delete(`/api/barbers/${id}/destroy`)
            commit('setBarberLoading', false)

            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setBarberLoading', false)
            return false
        }
    },
    async updateBarber({commit}, data) {
        commit('setBarberLoading', true)
        commit('setBarberErrors', {})
        try {
            let response = await axios.put(`/api/barbers/${data.id}/update`, data)
            commit('setBarberLoading', false)
            commit('setBarberErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setBarberLoading', false)
            commit('setBarberErrors', error.response.data.errors)
            return false
        }
    },
    async updateBarberServices({commit}, data) {
        commit('setBarberLoading', true)
        commit('setBarberErrors', {})
        try {
            let response = await axios.put(`/api/barbers/${data.id}/update-services`, data)
            commit('setBarberLoading', false)
            commit('setBarberErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setBarberLoading', false)
            commit('setBarberErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}