import axios from 'axios'

const state = {
    faq: {},
    faqs: {},
    faq_errors: {},
    faq_loading: false,
}

const getters = {
    faq: state => state.faq,
    faqs: state => state.faqs,
    faq_errors: state => state.faq_errors,
    faq_loading: state => state.faq_loading,
}

const mutations = {
    setFaq(state, faq) {
        state.faq = faq
    },
    setFaqs(state, faqs) {
        state.faqs = faqs
    },
    setFaqErrors(state, faq_errors) {
        state.faq_errors = faq_errors
    },
    setFaqLoading(state, faq_loading) {
        state.faq_loading = faq_loading
    },
}

const actions = {
    async storeFaq({commit}, data) {
        commit('setFaqLoading', true)
        commit('setFaqErrors', {})
        try {
            let response = await axios.post(`/api/faqs/store`, data)
            commit('setFaqLoading', false)
            commit('setFaqErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setFaqLoading', false)
            commit('setFaqErrors', error.response.data.errors)
            return false
        }
    },
    async getFaqs({commit}, data) {
        commit('setFaqLoading', true)
        try {
            let response = await axios.get(`/api/faqs`, data)
            commit('setFaqs', response.data)
            commit('setFaqLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setFaqLoading', false)

            return false
        }
    },

    async getFaq({commit}, id) {
        try {
            let response = await axios.get(`/api/faqs/${id}/show`)
            commit('setFaq', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },

    async destroyFaq({commit}, id) {
        commit('setFaqLoading', true)
        try {
            let response = await axios.delete(`/api/faqs/${id}/destroy`)
            commit('setFaqLoading', false)

            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setFaqLoading', false)
            return false
        }
    },
    async updateFaq({commit}, data) {
        commit('setFaqLoading', true)
        commit('setFaqErrors', {})
        try {
            let response = await axios.put(`/api/faqs/${data.id}/update`, data)
            commit('setFaqLoading', false)
            commit('setFaqErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setFaqLoading', false)
            commit('setFaqErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}