
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/Index/IndexPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'navbar',
            title: 'B-ZONE BARBERSHOP',
        }
    },
    {
        path: '/services',
        name: 'services',
        component: () => import('@/pages/Index/ServicesPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'navbar',
            title: 'B-ZONE BARBERSHOP',
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/Index/AboutPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'navbar',
            title: 'B-ZONE BARBERSHOP',
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/pages/Index/ContactPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'navbar',
            title: 'B-ZONE BARBERSHOP',
        }
    },
]

export default routes