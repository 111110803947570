import axios from 'axios'

const state = {
    service: {},
    services: {},
    service_errors: {},
    service_loading: false,
}

const getters = {
    service: state => state.service,
    services: state => state.services,
    service_errors: state => state.service_errors,
    service_loading: state => state.service_loading,
}

const mutations = {
    setService(state, service) {
        state.service = service
    },
    setServices(state, services) {
        state.services = services
    },
    setServiceErrors(state, service_errors) {
        state.service_errors = service_errors
    },
    setServiceLoading(state, service_loading) {
        state.service_loading = service_loading
    },
}

const actions = {
    async storeService({commit}, data) {
        commit('setServiceLoading', true)
        commit('setServiceErrors', {})
        try {
            let response = await axios.post(`/api/services/store`, data)
            commit('setServiceLoading', false)
            commit('setServiceErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setServiceLoading', false)
            commit('setServiceErrors', error.response.data.errors)
            return false
        }
    },
    async getServices({commit}, data) {
        commit('setServiceLoading', true)
        try {
            let response = await axios.get(`/api/services`, data)
            commit('setServices', response.data)
            commit('setServiceLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setServiceLoading', false)

            return false
        }
    },

    async getService({commit}, id) {
        try {
            let response = await axios.get(`/api/services/${id}/show`)
            commit('setService', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },

    async destroyService({commit}, id) {
        commit('setServiceLoading', true)
        try {
            let response = await axios.delete(`/api/services/${id}/destroy`)
            commit('setServiceLoading', false)

            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setServiceLoading', false)
            return false
        }
    },
    async updateService({commit}, data) {
        commit('setServiceLoading', true)
        commit('setServiceErrors', {})
        try {
            let response = await axios.put(`/api/services/${data.id}/update`, data)
            commit('setServiceLoading', false)
            commit('setServiceErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setServiceLoading', false)
            commit('setServiceErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}